import { STATUS_CODES } from 'http';
import uppercamelcase from 'uppercamelcase';

class HTTPError extends Error {
  statusCode: number;
  constructor(code, message, extras) {
    super(message || STATUS_CODES[code]);
    if (arguments.length >= 3 && extras) {
      Object.assign(this, extras);
    }
    this.name = toName(code);
    this.statusCode = code;
  }
}

/**
 * Converts an HTTP status code to an Error `name`.
 * Ex:
 *   302 => "Found"
 *   404 => "NotFoundError"
 *   500 => "InternalServerError"
 */

export function toName(code) {
  const suffix = ((code / 100) | 0) === 4 || ((code / 100) | 0) === 5 ? 'error' : '';
  return uppercamelcase(String(STATUS_CODES[code]).replace(/error$/i, ''), suffix);
}

export default HTTPError;
