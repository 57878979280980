import fetch from 'isomorphic-unfetch';
import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import HTTPError from '../utils/http-error';

export const redirect = (
  action: any,
  shopOrigin: string,
  redirectTo: string,
  host: string = null,
): void => {
  if (window.top === window.self) {
    window.location.assign(redirectTo);
  } else {
    const appConfig = {
      apiKey: process.env.SHOPIFY_API_KEY,
      shopOrigin: shopOrigin,
      host: host,
    };
    const app = createApp(appConfig);
    Redirect.create(app).dispatch(action, redirectTo);
  }
};

export default async function <JSON = any>(input: RequestInfo): Promise<JSON> {
  const res = await fetch(input, {
    headers: { Authorization: `Bearer ${localStorage.getItem('tuneboomAccessToken')}` },
    credentials: 'include',
  });

  const data = await res.json();
  if (res.status === 401 && data.redirectTo && data.shopOrigin) {
    redirect(Redirect.Action.REMOTE, data.shopOrigin, data.redirectTo);
    return data;
  }

  if (!res.ok) {
    throw new HTTPError(res.status, res.statusText, null);
  }

  return data;
}
